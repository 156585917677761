import React from 'react'
import { Link } from 'gatsby'

import logo from '../assets/images/Vixel Logo Cyber.svg'

const Header = props => (
  <header id="header" className="alt">
    <span className="logo">
      <Link to="/">
        <img src={logo} alt="Vixel Corp Logo" />
      </Link>
    </span>
  </header>
)

export default Header
