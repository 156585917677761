import React from 'react'
// import { Link } from 'gatsby'
import Helmet from 'react-helmet'
// import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pair-programming.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
// import Nav from '../components/Nav'

class Index extends React.Component {
  // constructor(props) {
  //   super(props)
  // this.state = {
  //   stickyNav: false,
  // }
  // }

  // _handleWaypointEnter = () => {
  //   this.setState(() => ({ stickyNav: false }))
  // }

  // _handleWaypointLeave = () => {
  //   this.setState(() => ({ stickyNav: true }))
  // }

  render() {
    return (
      <Layout>
        <Helmet title="Vixel Corp." />
        <Header />
        {/*
        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />
        */}
        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Welcome to Vixel Corp.</h2>
                </header>
                <p>
                  We are an IT consultancy based in Toronto, Canada. We bring
                  agile practices and a high level of technical expertise to
                  help build your project or product.
                </p>
              </div>
              <span className="image">
                <img src={pic01} alt="Pair Programming" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Expertise</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Code</h3>
                <p>
                  We develop in multiple languages including JavaScript, CSS,
                  HTML, Java, and Python.
                </p>
              </li>
              <li>
                <span className="icon major style3 fa-sitemap"></span>
                <h3>Design and Architecture</h3>
                <p>
                  We bring a practical and thoughtful approach to design, system
                  design, and technical architecture. We believe the right
                  design choices should be made from the beginning to ensure
                  project and product success.
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-users"></span>
                <h3>Process</h3>
                <p>
                  We take the best processes in the industry including agile and
                  extreme programming, to help plan and build features quickly
                  while getting the fastest feedback loop possible for your
                  product.
                </p>
              </li>
            </ul>
          </section>
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>We'd Love to Help!</h2>
                </header>
                <p>
                  If you are looking for a new role or you have a project you'd
                  like to be built please reach out! We are open for inquiries.
                  <br />
                  Send us an email at info@vixelcorp.com and we'll get back to
                  you!
                </p>
                <ul className="actions">
                  <li>
                    <a href="mailto:info@vixelcorp.com" className="button">
                      Send us an email
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
